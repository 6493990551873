@use '@angular/material' as mat;
@include mat.core();

/* For use in src/lib/core/theming/_palette.scss */
$md-halprime: (
    50 : #eff0f1,
    100 : #d7dbdd,
    200 : #bdc3c6,
    300 : #a3aaaf,
    400 : #8f989d,
    500 : #7b868c,
    600 : #737e84,
    700 : #687379,
    800 : #5e696f,
    900 : #4b565c,
    A100 : #c2e8fd,
    A200 : #90d5fb,
    A400 : #59c4ff,
    A700 : #40bbff,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #000000,
        400 : #000000,
        500 : #000000,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

/* For use in src/lib/core/theming/_palette.scss */
$md-darkteal: (
    50 : #e1eaea,
    100 : #b3caca,
    200 : #80a6a7,
    300 : #4d8283,
    400 : #276869,
    500 : #014d4e,
    600 : #014647,
    700 : #013d3d,
    800 : #013435,
    900 : #002525,
    A100 : #60ffff,
    A200 : #2dffff,
    A400 : #00f9f9,
    A700 : #00e0e0,
    contrast: (
        50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);



/* For use in src/lib/core/theming/_palette.scss */
$md-halsecondry: (
    50 : #f9e0e0,
    100 : #f0b3b3,
    200 : #e68080,
    300 : #db4d4d,
    400 : #d42626,
    500 : #cc0000,
    600 : #c70000,
    700 : #c00000,
    800 : #b90000,
    900 : #ad0000,
    A100 : #ffd7d7,
    A200 : #ffa4a4,
    A400 : #ff7171,
    A700 : #ff5858,
    contrast: (50 : #000000,
        100 : #000000,
        200 : #000000,
        300 : #ffffff,
        400 : #ffffff,
        500 : #ffffff,
        600 : #ffffff,
        700 : #ffffff,
        800 : #ffffff,
        900 : #ffffff,
        A100 : #000000,
        A200 : #000000,
        A400 : #000000,
        A700 : #000000,
    )
);

$primary: mat.define-palette($md-darkteal);

$accent :mat.define-palette($md-halsecondry);

$hal-typography: mat.define-typography-config($font-family: 'Rubik');

$hal-theme: mat.define-light-theme((color: (primary: $primary,
                accent: $accent,
            ),
            typography: $hal-typography,
        ));

@include mat.all-component-themes($hal-theme);


html,
body {
    height: 100%;
    margin: 0;
    font-family: "Rubik",sans-serif;
    background-color: var(--color-body-background); // rgba(252, 200, 209, 0.07);
}




.error-snackbar {
    margin-top: 300px !important;
    max-width: 400px;
    --mdc-snackbar-container-color: #F44336 !important;
    --mdc-snackbar-supporting-text-color: white !important;
    --mat-mdc-snack-bar-button-color: white !important;
    white-space: pre-wrap;
}

.success-snackbar {
    margin-top: 300px !important;
    max-width: 400px;
    --mdc-snackbar-container-color: #54ad58 !important;
    --mdc-snackbar-supporting-text-color: white !important;
    --mat-mdc-snack-bar-button-color: white !important;
    white-space: pre-wrap !important;
    ;
}

// .mat-table-div-block {
//     width: 80% !important;
//     margin-left: 25px !important;
//     margin-top: 15px !important;
// }

.mat-dialog-container {
    padding-top: 15px !important;
    border-style: initial;
    border: 1px solid !important;
    box-shadow: inset 0 3px 6px rgba(0, 0, 0, 0.16), 0 4px 6px rgba(0, 0, 0, 0.45);
    border-radius: 10px;
    border-color: var(--color-primary) !important;
    color: var(--color-primary) !important;
}

.mat-p-custom {
    margin: 10px !important;
    ;
}


.cancel-button {
    min-height: 40px;
    padding: 12.5px 24.5px;
    font-size: 110%;
    font-family: "Rubik", sans-serif;
    letter-spacing: 0.5px;
    background: #fff;
    color: red !important;
    border-radius: 0 !important;
    display: inline-block;
    border: 2px solid red !important;
} 

.mat-card-zero-margin {
    padding: 0 !important;
    margin-top: 0px !important;
}

.ck-editor__editable {
    min-height: 300px !important;
}

// .mat-header-row,
// .mat-row {
//     min-height: 32px !important;
// }

.spinner-center {
    position: absolute;
    top: 50%;
    left: 50%;
    -moz-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translateX(-50%) translateY(-50%);
    transform: translateX(-50%) translateY(-50%);
}

.spinner-right {
    position: absolute;
    top: 35%;
    left: 50%;
}

.table-even-row {
    background-color: #FFFAFA
}

// .mat-sort-header-arrow.ng-trigger.ng-trigger-arrowPosition {
//     opacity: 5 !important;
//     color: white;
// }

.mat-card-title-Header-custom {
    background-color: var(--color-primary) !important;
    color: white !important;
}

.div-primary-color {
    color: var(--color-primary) !important;
    background-color: white;
    padding: 7x;
    border-width: 2px;
    border-style: solid;
    border-color: var(--color-primary) !important;
    ;
}

mat-form-field {
    width: 100%;
}

mat-row {
    width: 100%;
}

.mat-card-sub-title-Header-custom {
    text-align: center !important;
    align-self: center !important;
    font-size: large !important;
}

mat-card-title {
    background-color: #4b565c;
    color: white !important;
    text-align: center !important;
    font-size: large !important;
}

li {

    color: black;
}

li::marker {
    color: red;
    font-weight: 700;
    font-size: 20px;
}


mat-card {
    @include mat.elevation(8);
    border-radius: 15px;
    margin-top: 15px;
}

.header {
    justify-content: space-between;
}


.header {
    position: sticky;
    position: -webkit-sticky;
    top: 0;
    z-index: 1000;
}

// table.mat-table,
// table {
//     width: 100%;
// }

.hamburger {
    margin-top: 5px;
    color: white;
    transform: scale(1.5);
    align-content: end;
    margin-right: 20px;
    display:flex;  
    float:right;  
    justify-content:space-between;

}



.btn {
    cursor: pointer;
    padding: 12.5px 24.5px;
    border-width: 0px;
    border-bottom: 1px solid #2d3a4d;
    background: #c00;
    color: #fff;
    font-family: "Rubik", sans-serif;
    letter-spacing: 0.5px;
    min-height: 40px;
    display: inline-block;
    border-radius: 0 !important;
}

  
.btnSmall {
    cursor: pointer;
    padding: 12.5px 24.5px;
    border-width: 0px;
    border-bottom: 1px solid #2d3a4d;
    background: #c00;
    color: #fff;
    font-family: "Rubik", sans-serif;
    letter-spacing: 0.5px;
    min-height: 40px;
    display: inline-block;
    border-radius: 0 !important;
}